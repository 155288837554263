import { defineStore } from 'pinia'
import { useContentStore } from '~/composables/stores/useContentStore.js'

export const viewsEndpoint = '/notion/workspaces/{workspaceId}/views'
export const singleViewEndpoint = '/notion/views/{slug}'

export const useViewsStore = defineStore('views', () => {

  const contentStore = useContentStore('slug')
  const allLoaded = ref(false)
  const currentPage = ref(1)
  const loadAllRequest = ref(null)

  const loadAll = (workspaceId) => {
    if (loadAllRequest.value) {
      return loadAllRequest.value // Return existing promise if there's an ongoing request
    }
    if (!workspaceId) {
      return
    }
    contentStore.startLoading()
    // eslint-disable-next-line
    loadAllRequest.value = new Promise(async (resolve, reject) => {
      try {
        const response = await noteFormsFetch(viewsEndpoint.replace('{workspaceId}', workspaceId), { query: { page: currentPage.value } })
        if (currentPage.value === 1) {
          contentStore.resetState() // Reset state if it's the first page
          contentStore.save(response.data)
        } else {
          contentStore.save(response.data)
        }
        if (currentPage.value < response.meta.last_page) {
          currentPage.value++
          loadAllRequest.value = null // Clear the request promise to allow new requests
          loadAll(workspaceId) // Recursively load the next page
        } else {
          allLoaded.value = true
          contentStore.stopLoading()
          loadAllRequest.value = null
          currentPage.value = 1
        }
        resolve()
      } catch (err) {
        contentStore.stopLoading()
        currentPage.value = 1
        loadAllRequest.value = null
        reject(err)
      }
    })

    return loadAllRequest.value
  }

  const load = (workspaceId, slug) => {
    contentStore.startLoading()
    return noteFormsFetch(viewsEndpoint.replace('{workspaceId}', workspaceId) + '/' + slug)
      .finally(() => {
        contentStore.stopLoading()
      })
  }

  const loadView = (slug) => {
    contentStore.startLoading()
    return noteFormsFetch(singleViewEndpoint.replace('{slug}', slug))
      .then(response => {
        contentStore.save(response)
      })
      .finally(() => {
        contentStore.stopLoading()
      })
  }

  /**
   * Load a view from the public API
   */
  const publicLoad = (slug) => {
    contentStore.startLoading()
    return useNoteFormsApi('/views/' + slug)
  }

  const publicFetch = (slug) => {
    contentStore.startLoading()
    return noteFormsFetch('/views/' + slug)
  }

  const allTags = computed(() => {
    let tags = []
    contentStore.getAll.value.forEach((view) => {
      if (view.tags && view.tags.length) {
        if (typeof view.tags === 'string' || view.tags instanceof String) {
          tags = tags.concat(view.tags.split(','))
        } else if (Array.isArray(view.tags)) {
          tags = tags.concat(view.tags)
        }
      }
    })
    return [...new Set(tags)]
  })

  return {
    ...contentStore,
    allLoaded,
    allTags,
    publicLoad,
    publicFetch,
    loadAll,
    loadView,
    load
  }
})
